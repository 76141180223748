<template>
  <a-card
    :bodyStyle="{ height: '63vh', overflowY: 'auto' }"
    id="obeya-room-videos-card"
    :loading="isFetchingVideos"
  >
    <template #title>
      <div class="d-flex align-items-center">
        <a-typography-title :level="5" class="my-0">
          Total Videos: &nbsp;
          <a-tag color="blue" id="obeya-room-videos-count">{{ count }}</a-tag>
        </a-typography-title>
        <template v-if="videosCount">
          <label class="ml-auto mr-2 text-secondary">Sort By:</label>
          <a-select
            :value="sortBy"
            @change="(value) => $emit('setSortBy', value)"
            :options="optionsForSorting"
            style="width: 10vw"
          ></a-select>
        </template>
      </div>
    </template>
    <a-modal
      v-model:visible="showVideo"
      :title="videoToPlay?.fileName"
      centered
      destroyOnClose
      id="obeya-room-video-modal"
    >
      <template #footer>
        <a-row>
          <a-col span="14">
            <a-descriptions title="Details" class="text-start">
              <a-descriptions-item label="File Name: " span="3">
                <a-tag color="blue">
                  {{ videoToPlay?.fileName }}
                </a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="Duration: ">
                <a-tag color="blue">
                  {{ videoToPlay?.duration }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </template>
      <div
        class="w-100 d-flex"
        style="height: 350px !important"
        v-if="isfetchingVideoUrl"
      >
        <a-spin class="m-auto" size="large" />
      </div>
      <video
        class="w-100"
        v-else
        controls
        :src="videoToPlay?.fileURL"
        id="obeya-room-modal-video"
      ></video>
    </a-modal>
    <a-row
      v-if="list.length > 0"
      :gutter="[32, 24]"
      id="obeya-room-video-list"
      class="d-flex justify-content-between"
    >
      <video-item
        v-for="(item, index) in list"
        :key="index"
        :item="item"
        @handlePlay="handleVideoPlay"
      >
      </video-item>
    </a-row>
    <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
  </a-card>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import { mapGetters } from 'vuex';
import VideoService from '../../../../services/videos';
import VideoItem from './VideoItem.vue';

export default {
  setup() {
    const optionsForSorting = [
      {
        value: 'none',
        label: 'None',
      },
      {
        value: '-',
        label: 'Most Commented',
      },
      {
        value: '',
        label: 'Less Commented',
      },
    ];
    return { Empty, optionsForSorting };
  },
  components: {
    DeleteOutlined,
    VideoItem,
  },
  props: ['count', 'list', 'sortBy', 'getVideoS3Details'],
  emits: ['setSortBy'],

  data() {
    return {
      showVideo: false,
      videoToPlay: null,
      isfetchingVideoUrl: false,
    };
  },
  computed: {
    ...mapGetters(['isFetchingVideos', 'videosCount']),
  },
  methods: {
    getVideoUrl(video) {
      return new Promise(async (resolve, reject) => {
        const { bucket, filePath } = this.getVideoS3Details(video);
        const payload = {
          bucket_name: bucket,
          object_path: filePath,
        };
        const [error, data] = await VideoService.getPresignedUrl(payload);
        if (error) {
          console.log('error while fetching url');
          reject(error);
        }
        resolve(data);
      });
    },

    async handleVideoPlay(item) {
      if (!item.fileURL) {
        this.isfetchingVideoUrl = true;
        const { presigned_url } = await this.getVideoUrl(item);
        this.isfetchingVideoUrl = false;
        if (presigned_url) item = { ...item, fileURL: presigned_url };
      }
      this.videoToPlay = item;
      this.showVideo = true;
    },
  },
};
</script>

<style></style>
